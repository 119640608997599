<mat-accordion multi>
  <mat-expansion-panel *ngIf="$requirements().languages?.list.length > 0" #languagePanel>
    <mat-expansion-panel-header>
      <ng-container
        [ngTemplateOutlet]="panelHeader"
        [ngTemplateOutletContext]="{
          title: 'GENERAL.LANGUAGE' | translate,
          mandatory: $requirements()?.languages?.mandatory,
          expanded: languagePanel.expanded
        }"
      />
    </mat-expansion-panel-header>
    <div class="d-flex align-items-center gap-2" *ngFor="let language of $requirements().languages?.list">
      <li class="primary-color">
        <span> {{ language.language.isoCode | enumTranslate: 'ENUM.RECRUITMENT.LANGUAGES' : { default: language.language.name } }}: </span>
      </li>
      <span>
        {{ LanguageLevel[language.level] | enumTranslate: 'ENUM.RECRUITMENT.LEVEL' }}
      </span>
      <mat-icon *ngIf="language.exclusive" class="exclusive-icon">release_alert</mat-icon>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel *ngIf="$requirements().academicTrainings?.list.length > 0" #academicTrainingPanel>
    <mat-expansion-panel-header>
      <ng-container
        [ngTemplateOutlet]="panelHeader"
        [ngTemplateOutletContext]="{
          title: 'RECRUITMENT.ACADEMIC_TRAINING' | translate,
          mandatory: $requirements()?.academicTrainings?.mandatory,
          expanded: academicTrainingPanel.expanded
        }"
      />
    </mat-expansion-panel-header>
    <div class="d-flex align-items-center gap-2" *ngFor="let training of $requirements().academicTrainings.list">
      <li class="primary-color">
        <span>{{ training.training?.name }}</span>
      </li>
      <span>{{ AcademicTrainingLevel[training.level] | enumTranslate: 'ENUM.RECRUITMENT.TRAINING_LEVEL' }}</span>
      <mat-icon *ngIf="training.mandatory" class="exclusive-icon">release_alert</mat-icon>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel *ngIf="$requirements().complementaryTrainings?.list.length > 0" #complementaryTrainingPanel>
    <mat-expansion-panel-header>
      <ng-container
        [ngTemplateOutlet]="panelHeader"
        [ngTemplateOutletContext]="{
          title: 'RECRUITMENT.COMPLEMENTARY_TRAINING' | translate,
          mandatory: $requirements()?.complementaryTrainings?.mandatory,
          expanded: complementaryTrainingPanel.expanded
        }"
      />
    </mat-expansion-panel-header>
    <div class="d-flex align-items-center gap-2" *ngFor="let training of $requirements().complementaryTrainings.list">
      <li>
        <span>{{ training.training?.name }}</span>
      </li>
      <mat-icon *ngIf="training.mandatory" class="exclusive-icon">release_alert</mat-icon>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel *ngIf="$requirements().transfers?.list.length > 0" #transferPanel>
    <mat-expansion-panel-header>
      <ng-container
        [ngTemplateOutlet]="panelHeader"
        [ngTemplateOutletContext]="{
          title: 'RECRUITMENT.TRANSFER' | translate,
          mandatory: $requirements()?.transfers?.mandatory,
          expanded: transferPanel.expanded
        }"
      />
    </mat-expansion-panel-header>
    <div class="d-flex align-items-center gap-2" *ngFor="let transfer of $requirements().transfers?.list">
      <li>
        <span>
          {{ TransferType[transfer.type] | enumTranslate: 'ENUM.RECRUITMENT.TRANSFER_TYPES' }}
        </span>
      </li>
      <mat-icon *ngIf="transfer.exclusive" class="exclusive-icon">release_alert</mat-icon>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel *ngIf="$requirements().technicalSkills?.list.length > 0" #technicalSkillPanel>
    <mat-expansion-panel-header>
      <ng-container
        [ngTemplateOutlet]="panelHeader"
        [ngTemplateOutletContext]="{
          title: 'RECRUITMENT.TECHNICAL_SKILLS' | translate,
          mandatory: $requirements()?.technicalSkills?.mandatory,
          expanded: technicalSkillPanel.expanded
        }"
      />
    </mat-expansion-panel-header>
    <div class="d-flex align-items-center gap-2" *ngFor="let techSkill of $requirements().technicalSkills.list">
      <li class="primary-color">
        <span>{{ techSkill.technicalSkill?.name }}</span>
      </li>
      <span>{{ TechnicalSkillLevel[techSkill.level] | enumTranslate: 'ENUM.RECRUITMENT.TECHNICAL_SKILL_LEVEL' }}</span>
      <mat-icon *ngIf="techSkill.mandatory" class="exclusive-icon">release_alert</mat-icon>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel *ngIf="$requirements().softSkills?.list.length > 0" #softSkillPanel>
    <mat-expansion-panel-header>
      <ng-container
        [ngTemplateOutlet]="panelHeader"
        [ngTemplateOutletContext]="{
          title: 'RECRUITMENT.SOFT_SKILLS' | translate,
          mandatory: $requirements()?.softSkills?.mandatory,
          expanded: softSkillPanel.expanded
        }"
      />
    </mat-expansion-panel-header>
    <div class="d-flex align-items-center gap-2" *ngFor="let softSkill of $requirements().softSkills.list">
      <li>
        <span>{{ softSkill.softSkill?.name }}</span>
      </li>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<ng-template #panelHeader let-title="title" let-mandatory="mandatory" let-expanded="expanded">
  <mat-panel-title class="d-flex align-items-center gap-2">
    <h3 class="primary-color">{{ title }}</h3>
    <mat-icon *ngIf="mandatory && !expanded" class="exclusive-icon">release_alert</mat-icon>
  </mat-panel-title>
</ng-template>
