<h1 mat-dialog-title class="text-center">{{ 'LOWER_MENU.QR_CLOCK_IN_TERMINAL' | translate }}</h1>
<mat-dialog-content class="mat-typography">
  <mat-form-field class="w-100 mt-1">
    <mat-label>{{ 'TIME_MANAGEMENT.TERMINAL_NAME' | translate }}</mat-label>
    <input matInput required [(ngModel)]="terminal" />
  </mat-form-field>
  <p class="color-gray-light">
    {{ 'TIME_MANAGEMENT.QR_CLOCK_IN_DISCLAIMER' | translate }}
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button (click)="cancel()">{{ 'MODALS.CANCEL' | translate }}</button>
  <button mat-raised-button (click)="accept()" mat-raised-button color="primary" cdkFocusInitial>{{ 'MODALS.ACCEPT' | translate }}</button>
</mat-dialog-actions>
