/* eslint-disable prefer-template */
/* eslint-disable no-useless-concat */
import { convertJsonDates } from 'src/app/shared/shared-functions';
import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs'
import { Constants } from 'src/app/shared/constants';
import { DataDTO } from 'src/app/api/interfaces/data.interface';

import { AuditLogEntryDTO } from 'src/app/api/interfaces/audit-log-entry.interface';
import { ImageEntityDTO } from 'src/app/api/interfaces/image-entity.interface';
import { PublicLinkDTO } from 'src/app/api/interfaces/public-link.interface';
import { ComentarioDTO } from 'src/app/api/interfaces/comentario.interface';
import { PaginatorDataReclutamientoCandidatoBasicoDTO } from 'src/app/api/interfaces/paginator-data-reclutamiento-candidato-basico.interface';
import { ReclutamientoCandidatoDTO } from 'src/app/api/interfaces/reclutamiento-candidato.interface';
import { ReclutamientoCandidatoNuevaFaseDTO } from 'src/app/api/interfaces/reclutamiento-candidato-nueva-fase.interface';
import { ReclutamientoCandidatoParaFaseDTO } from 'src/app/api/interfaces/reclutamiento-candidato-para-fase.interface';
import { ReclutamientoCandidatoRecomendadoDTO } from 'src/app/api/interfaces/reclutamiento-candidato-recomendado.interface';
import { ReclutamientoFaseCandidatoDTO } from 'src/app/api/interfaces/reclutamiento-fase-candidato.interface';

@Injectable({
   providedIn: 'root'
})
export class ReclutamientoCandidatosBackendService {

   constructor(protected http: HttpClient) { }


   public getReclutamientoCandidatosIdAuditory(id: number): Observable<AuditLogEntryDTO[]> {
      return this.http.get<DataDTO<AuditLogEntryDTO>>(Constants.apiURL + '/reclutamientoCandidatos/' + id + '/Auditory').pipe(map((res) => convertJsonDates(res.data) as AuditLogEntryDTO[]));
   }

   public getReclutamientoCandidatos(params: { pageNumber: number, pageSize: number, fechaDesde: Date, fechaHasta: Date, searchValue?: string, featured?: boolean, sortActive?: number, isAsc?: boolean, candidateTypes?: number, tagIds?: string, rejectionReasonIds?: string, recruitState?: number }, ): Observable<PaginatorDataReclutamientoCandidatoBasicoDTO> {
      const { pageNumber, pageSize, fechaDesde, fechaHasta, searchValue = '', featured = false, sortActive = 0, isAsc = false, candidateTypes = 0, tagIds = '', rejectionReasonIds = '', recruitState = 0 } = params;
      return this.http.get<PaginatorDataReclutamientoCandidatoBasicoDTO>(Constants.apiURL + '/reclutamientoCandidatos/' + '?pageNumber=' + pageNumber +
            '&pageSize=' + pageSize +
            '&fechaDesde=' + (fechaDesde?.toISOString() ?? 'undefined') +
            '&fechaHasta=' + (fechaHasta?.toISOString() ?? 'undefined') +
            '&searchValue=' + searchValue +
            '&featured=' + featured +
            '&sortActive=' + sortActive +
            '&isAsc=' + isAsc +
            '&candidateTypes=' + candidateTypes +
            '&tagIds=' + tagIds +
            '&rejectionReasonIds=' + rejectionReasonIds +
            '&recruitState=' + recruitState).pipe(map((res) => convertJsonDates(res) as PaginatorDataReclutamientoCandidatoBasicoDTO ));
   }

   public getReclutamientoCandidatosId(id: number): Observable<ReclutamientoCandidatoDTO> {
      return this.http.get<ReclutamientoCandidatoDTO>(Constants.apiURL + '/reclutamientoCandidatos/' + id).pipe(map((res) => convertJsonDates(res) as ReclutamientoCandidatoDTO ));
   }

   public getReclutamientoCandidatosIdComentarios(id: number): Observable<ComentarioDTO[]> {
      return this.http.get<DataDTO<ComentarioDTO>>(Constants.apiURL + '/reclutamientoCandidatos/' + id + '/comentarios').pipe(map((res) => convertJsonDates(res.data) as ComentarioDTO[]));
   }

   public insertReclutamientoCandidatosIdComentario(id: number, stream: FormData): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/reclutamientoCandidatos/' + id + '/comentario', stream);
   }
   public updateReclutamientoCandidatosIdComentariosIDCOMENTARIO(id: number, iDComentario: number, comentarioDTO: ComentarioDTO): Observable<ComentarioDTO> {
      return this.http.put<ComentarioDTO>(Constants.apiURL + '/reclutamientoCandidatos/' + id + '/comentarios/' + iDComentario, comentarioDTO).pipe(map((res) => convertJsonDates(res) as ComentarioDTO ));
   }

   public deleteReclutamientoCandidatosIdComentariosIDCOMENTARIO(id: number, iDComentario: number): Observable<void> {
      return this.http.delete<void>(Constants.apiURL + '/reclutamientoCandidatos/' + id + '/comentarios/' + iDComentario);
   }
   public getReclutamientoCandidatosEmail(email: string): Observable<ReclutamientoCandidatoDTO> {
      return this.http.get<ReclutamientoCandidatoDTO>(Constants.apiURL + '/reclutamientoCandidatos/email' + '?email=' + email).pipe(map((res) => convertJsonDates(res) as ReclutamientoCandidatoDTO ));
   }

   public insertReclutamientoCandidatos(reclutamientoCandidatoInputDTO: FormData): Observable<ReclutamientoCandidatoDTO> {
      return this.http.post<ReclutamientoCandidatoDTO>(Constants.apiURL + '/reclutamientoCandidatos/', reclutamientoCandidatoInputDTO).pipe(map((res) => convertJsonDates(res) as ReclutamientoCandidatoDTO ));
   }

   public updateReclutamientoCandidatosId(id: number, reclutamientoCandidatoInputDTO: FormData): Observable<ReclutamientoCandidatoDTO> {
      return this.http.put<ReclutamientoCandidatoDTO>(Constants.apiURL + '/reclutamientoCandidatos/' + id, reclutamientoCandidatoInputDTO).pipe(map((res) => convertJsonDates(res) as ReclutamientoCandidatoDTO ));
   }

   public insertReclutamientoCandidatosCandidateIdImage(id: number, imageEntityDTO: ImageEntityDTO): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/reclutamientoCandidatos/candidate/' + id + '/Image', imageEntityDTO);
   }
   public insertReclutamientoCandidatosIdFase(id: number, reclutamientoCandidatoNuevaFaseDTO: ReclutamientoCandidatoNuevaFaseDTO): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/reclutamientoCandidatos/' + id + '/fase', reclutamientoCandidatoNuevaFaseDTO);
   }
   public updateReclutamientoCandidatosIdOfertaIDOFERTAPostulacionesUnreject(id: number, iDOferta: number): Observable<void> {
      return this.http.put<void>(Constants.apiURL + '/reclutamientoCandidatos/' + id + '/oferta/' + iDOferta + '/postulaciones/unreject', null);
   }
   public deleteReclutamientoCandidatosId(id: number): Observable<void> {
      return this.http.delete<void>(Constants.apiURL + '/reclutamientoCandidatos/' + id);
   }
   public deleteReclutamientoCandidatosIdOfertaIDOFERTAPostulaciones(id: number, iDOferta: number, rejectionReasonId: number = undefined): Observable<void> {
      return this.http.delete<void>(Constants.apiURL + '/reclutamientoCandidatos/' + id + '/oferta/' + iDOferta + '/postulaciones' + '?rejectionReasonId=' + rejectionReasonId);
   }
   public insertReclutamientoCandidatosIdTalento(id: number): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/reclutamientoCandidatos/' + id + '/talento', null);
   }
   public deleteReclutamientoCandidatosIdTalento(id: number): Observable<void> {
      return this.http.delete<void>(Constants.apiURL + '/reclutamientoCandidatos/' + id + '/talento');
   }
   public insertReclutamientoCandidatosIdRecomendados(id: number): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/reclutamientoCandidatos/' + id + '/recomendados', null);
   }
   public deleteReclutamientoCandidatosIdRecomendados(id: number): Observable<void> {
      return this.http.delete<void>(Constants.apiURL + '/reclutamientoCandidatos/' + id + '/recomendados');
   }
   public getReclutamientoCandidatosRecomendados(params: { pageNumber: number, pageSize: number, fechaDesde: Date, fechaHasta: Date, searchValue?: string, sortActive?: number, isAsc?: boolean }, ): Observable<ReclutamientoCandidatoRecomendadoDTO[]> {
      const { pageNumber, pageSize, fechaDesde, fechaHasta, searchValue = '', sortActive = 0, isAsc = false } = params;
      return this.http.get<DataDTO<ReclutamientoCandidatoRecomendadoDTO>>(Constants.apiURL + '/reclutamientoCandidatos/recomendados' + '?pageNumber=' + pageNumber +
            '&pageSize=' + pageSize +
            '&fechaDesde=' + (fechaDesde?.toISOString() ?? 'undefined') +
            '&fechaHasta=' + (fechaHasta?.toISOString() ?? 'undefined') +
            '&searchValue=' + searchValue +
            '&sortActive=' + sortActive +
            '&isAsc=' + isAsc).pipe(map((res) => convertJsonDates(res.data) as ReclutamientoCandidatoRecomendadoDTO[]));
   }

   public getReclutamientoCandidatosRecomendadosId(id: number): Observable<ReclutamientoCandidatoDTO> {
      return this.http.get<ReclutamientoCandidatoDTO>(Constants.apiURL + '/reclutamientoCandidatos/recomendados/' + id).pipe(map((res) => convertJsonDates(res) as ReclutamientoCandidatoDTO ));
   }

   public getReclutamientoCandidatosRecomendadosIdCvpubliclink(id: number): Observable<PublicLinkDTO> {
      return this.http.get<PublicLinkDTO>(Constants.apiURL + '/reclutamientoCandidatos/recomendados/' + id + '/cv-public-link').pipe(map((res) => convertJsonDates(res) as PublicLinkDTO ));
   }

   public getReclutamientoCandidatosCommunityId(id: number): Observable<ReclutamientoCandidatoDTO> {
      return this.http.get<ReclutamientoCandidatoDTO>(Constants.apiURL + '/reclutamientoCandidatos/community/' + id).pipe(map((res) => convertJsonDates(res) as ReclutamientoCandidatoDTO ));
   }

   public insertReclutamientoCandidatosRecomendadosIdTalento(id: number, reclutamientoCandidatoDTO: ReclutamientoCandidatoDTO): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/reclutamientoCandidatos/recomendados/' + id + '/talento', reclutamientoCandidatoDTO);
   }
   public insertReclutamientoCandidatosRecomendadosIdImportar(id: number): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/reclutamientoCandidatos/recomendados/' + id + '/importar', null);
   }
   public getReclutamientoCandidatosOfertaIdFaseIDFASE(params: { id: number, iDFase: number, pageNumber: number, pageSize: number, fechaDesde: Date, fechaHasta: Date, searchValue?: string, sortActive?: number, isAsc?: boolean }, ): Observable<ReclutamientoCandidatoParaFaseDTO[]> {
      const { id, iDFase, pageNumber, pageSize, fechaDesde, fechaHasta, searchValue = '', sortActive = 0, isAsc = false } = params;
      return this.http.get<DataDTO<ReclutamientoCandidatoParaFaseDTO>>(Constants.apiURL + '/reclutamientoCandidatos/oferta/' + id + '/fase/' + iDFase + '?pageNumber=' + pageNumber +
            '&pageSize=' + pageSize +
            '&fechaDesde=' + (fechaDesde?.toISOString() ?? 'undefined') +
            '&fechaHasta=' + (fechaHasta?.toISOString() ?? 'undefined') +
            '&searchValue=' + searchValue +
            '&sortActive=' + sortActive +
            '&isAsc=' + isAsc).pipe(map((res) => convertJsonDates(res.data) as ReclutamientoCandidatoParaFaseDTO[]));
   }

   public getReclutamientoCandidatosOfertaId(params: { id: number, pageNumber: number, pageSize: number, fechaDesde: Date, fechaHasta: Date, searchValue?: string, sortActive?: number, isAsc?: boolean, rejectionReasonId?: number }, ): Observable<ReclutamientoCandidatoParaFaseDTO[]> {
      const { id, pageNumber, pageSize, fechaDesde, fechaHasta, searchValue = '', sortActive = 0, isAsc = false, rejectionReasonId = undefined } = params;
      return this.http.get<DataDTO<ReclutamientoCandidatoParaFaseDTO>>(Constants.apiURL + '/reclutamientoCandidatos/oferta/' + id + '?pageNumber=' + pageNumber +
            '&pageSize=' + pageSize +
            '&fechaDesde=' + (fechaDesde?.toISOString() ?? 'undefined') +
            '&fechaHasta=' + (fechaHasta?.toISOString() ?? 'undefined') +
            '&searchValue=' + searchValue +
            '&sortActive=' + sortActive +
            '&isAsc=' + isAsc +
            '&rejectionReasonId=' + rejectionReasonId).pipe(map((res) => convertJsonDates(res.data) as ReclutamientoCandidatoParaFaseDTO[]));
   }

   public getReclutamientoCandidatosIdOfertaIDOFERTA(id: number, iDOferta: number): Observable<ReclutamientoFaseCandidatoDTO> {
      return this.http.get<ReclutamientoFaseCandidatoDTO>(Constants.apiURL + '/reclutamientoCandidatos/' + id + '/oferta/' + iDOferta).pipe(map((res) => convertJsonDates(res) as ReclutamientoFaseCandidatoDTO ));
   }
}
