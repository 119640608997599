import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription, timer } from 'rxjs';
import { searchDelay } from 'src/app/shared/constants';
import { OWL_EMPTY } from 'src/app/shared/owl-images';

@Component({
  selector: 'app-warning-card',
  templateUrl: './warning-card.component.html',
  styleUrls: ['./warning-card.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatCardModule, MatButtonModule, MatIconModule, TranslateModule],
})
export class WarningCardComponent implements OnInit, OnDestroy {
  @Input() public mensaje = 'Error';
  @Input() public default: boolean;
  @Input() public delay = searchDelay;
  @Input() public showCloseButton = false;

  public show = false;
  public readonly emptyStateImg = OWL_EMPTY;

  private sub = new Subscription();

  constructor(private changeDetector: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.sub.add(
      timer(this.delay).subscribe(() => {
        this.show = true;
        this.changeDetector.markForCheck();
      }),
    );
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
