import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { PersonalEntregaModelDTO, SolicitudEntregaModelDTO } from 'src/app/ModelDTO';
import { EstadoAprobacionSolicitud, EstadoEntrega, PersonalEntregaDTO } from 'src/app/ModelDTO/DTO';
import { SolicitudEntregaServiceBackend } from 'src/app/ServiceBackend';
import { Command, ICommand } from 'src/app/shared/lib/ngx-neo-directives-mat/public_api';

export interface EntregaModalResult {
  entregaDTO: PersonalEntregaDTO;
  actionEntrega: ActionsEntrega;
}

export enum ActionsEntrega {
  DarDeBaja,
  CambiarEstado,
  VerDetalle,
}

@Component({
  selector: 'app-detalle-entrega-modal',
  templateUrl: './detalle-entrega-modal.component.html',
})
export class DetalleEntregaModalComponent implements OnInit {
  public entregaModel: PersonalEntregaModelDTO;
  public entregaModelAux: PersonalEntregaModelDTO;
  public estadoAprobacionEnum = EstadoAprobacionSolicitud;
  public estadoEntregaEnum = EstadoEntrega;
  public actionEntrega: ActionsEntrega;
  public error: boolean;
  public solicitudEntregaModel: SolicitudEntregaModelDTO;
  public vistaEmpresa$ = this.headerService.vistaEmpresa$;
  public eliminarCmd: ICommand = new Command(() => this.eliminar(), new BehaviorSubject(true), false);
  public cambiarEstadoCmd: ICommand = new Command(() => this.cambiarEstado(), new BehaviorSubject(true), false);

  public get status(): string {
    return this.solicitudEntregaModel?.Id > 0
      ? this.translate.instant(`ENUM.DELIVERIES.REQUEST_STATUS.${this.solicitudEntregaModel.Estado}`)
      : this.translate.instant(`ENUM.DELIVERIES.DELIVERY_STATUS.${this.entregaModel.Estado}`);
  }

  public get chipColor(): string {
    if (this.solicitudEntregaModel?.Id > 0) {
      switch (this.solicitudEntregaModel.getEntityDTO().estado) {
        case EstadoAprobacionSolicitud.Aprobado:
          return 'success';
        case EstadoAprobacionSolicitud.Rechazado:
          return 'danger';
        default:
          return 'accent';
      }
    } else if (this.entregaModel) {
      switch (this.entregaModel.getEntityDTO().estado) {
        case EstadoEntrega.Entregado:
          return 'info';
        case EstadoEntrega.Rechazado:
          return 'warn';
        default:
          return 'accent';
      }
    }
    return null;
  }

  private estadoOriginal: string;

  constructor(
    private headerService: HeaderAppService,
    protected administradorSolicitudEntregas: SolicitudEntregaServiceBackend,
    @Inject(MAT_DIALOG_DATA) public data: PersonalEntregaDTO,
    private dialogRef: MatDialogRef<DetalleEntregaModalComponent>,
    private translate: TranslateService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.entregaModel = new PersonalEntregaModelDTO(this.data);
    this.entregaModelAux = new PersonalEntregaModelDTO(JSON.parse(JSON.stringify(this.data)));
    this.entregaModelAux.Estado = this.entregaModel.Estado;
    this.estadoOriginal = EstadoEntrega[this.data.estado];
    this.actionEntrega = ActionsEntrega.VerDetalle;
    this.error = false;
    if (this.entregaModel.ConSolicitud) {
      const solicitudEntrega = await this.administradorSolicitudEntregas.getSolicitudEntregaIdPersonalEntrega(
        this.entregaModel.getEntityDTO().id,
      );
      this.solicitudEntregaModel = new SolicitudEntregaModelDTO(solicitudEntrega);
    }
  }

  public close(): void {
    this.dialogRef.close();
  }

  public eliminar(): void {
    const result: EntregaModalResult = {
      entregaDTO: this.entregaModel.getEntityDTO(),
      actionEntrega: ActionsEntrega.DarDeBaja,
    };
    this.dialogRef.close(result);
  }

  public cambiarEstado(): void {
    if (this.entregaModelAux.Estado !== this.estadoOriginal) {
      const result: EntregaModalResult = {
        entregaDTO: this.entregaModelAux.getEntityDTO(),
        actionEntrega: ActionsEntrega.CambiarEstado,
      };
      this.dialogRef.close(result);
    } else {
      this.error = true;
    }
  }
}
