import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { EventoDTO } from 'src/app/ModelDTO/DTO';
import { urlWeb } from 'src/app/shared/constants';
import { CordovaService } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { NamedBlobDTO } from '@api/interfaces/named-blob.dto';
import { FileDownloaderService } from './fileDownloader.service';

const GOOGLE_URL = 'https://www.google.com/calendar/render?action=TEMPLATE';
const GOOGLE_DATE_FORMAT = 'yyyyMMddThhmmssZ';
const GOOGLE_ALL_DAY_FORMAT = 'yyyyMMdd';
const ICS_DATE_FORMAT = 'yyyyMMdd';
const ICS_TIME_FORMAT = 'hhmm';

@Injectable({
  providedIn: 'root',
})
export class AddToCalendarService {
  constructor(
    private cordovaService: CordovaService,
    private fileDownloaderService: FileDownloaderService,
  ) {}

  public openGoogleCalendar(evento: EventoDTO): void {
    const format = evento.todoElDia ? GOOGLE_ALL_DAY_FORMAT : GOOGLE_DATE_FORMAT;

    const dates = `dates=${DateTime.fromJSDate(evento.fechaInicio).toFormat(format)}/${DateTime.fromJSDate(evento.fechaFin)
      .plus({ days: evento.todoElDia ? 1 : 0 })
      .toFormat(format)}`;

    const address = `location=${evento.ubicacion || ''}`;
    const props = `sprop=${urlWeb}`;

    const params = encodeURI([dates, address, props].filter(Boolean).join('&'));

    const text = `text=${encodeURIComponent(evento.titulo)}`;
    const description = evento.descripcion ? `details=${encodeURIComponent(evento.descripcion)}` : '';

    const url = [GOOGLE_URL, params, text, description].filter(Boolean).join('&');

    this.cordovaService.openLink(url);
  }

  public downloadIcs(evento: EventoDTO): void {
    const from = DateTime.fromJSDate(evento.fechaInicio).toUTC();
    const to = DateTime.fromJSDate(evento.fechaFin).toUTC();
    const url = [
      'BEGIN:VCALENDAR',
      'PRODID:-//DocMe Event//EN',
      'VERSION:2.0',
      'CALSCALE:GREGORIAN',
      'X-MS-OLK-FORCEINSPECTOROPEN:true',
      'METHOD:PUBLISH',
      'BEGIN:VEVENT',
      'UID:docme-event-1',
      `URL:${urlWeb}`,
      `URL: `,
      `DTSTAMP:${from.toFormat(ICS_DATE_FORMAT)}T${from.toFormat(ICS_TIME_FORMAT)}00Z`,
      `DTSTART:${from.toFormat(ICS_DATE_FORMAT)}T${from.toFormat(ICS_TIME_FORMAT)}00Z`,
      `DTEND:${to?.toFormat(ICS_DATE_FORMAT) || ''}T${to?.toFormat(ICS_TIME_FORMAT) || ''}00Z`,
      `SUMMARY:${evento.titulo || ''}`,
      `DESCRIPTION:${evento.descripcion || ''}`,
      `LOCATION:${evento.ubicacion || ''}`,
      `LOCATION: `,
      'SEQUENCE:0',
      'END:VEVENT',
      'END:VCALENDAR',
    ].join('\n');

    const namedBlob: NamedBlobDTO = new NamedBlobDTO();
    namedBlob.blob = new Blob([url], { type: 'text/calendar' });
    namedBlob.name = `${evento.titulo ?? 'event'}.ics`;
    this.fileDownloaderService.saveAs(namedBlob);
  }
}
