import { IndustryDTO } from '@api/interfaces/industry.interface';
import { DomicilioDTO } from 'src/app/ModelDTO/DTO/domicilio.DTO';
import { ElectronicSignatureType } from 'src/app/ModelDTO/DTO/electronicSignatureType.ENUM';
import { PaisDTO } from 'src/app/ModelDTO/DTO/pais.DTO';
import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';


export class EmpresaDTO implements IEntityDTO {

   domicilio: DomicilioDTO;
   pais: PaisDTO;
   name = '';
   telefono = '';
   artPoliza = '';
   artNombre = '';
   artPrivado = '';
   artPublico = '';
   razonSocial = '';
   identificadorFiscal = '';
   sucursal = 0;
   uaalooImage = '';
   timeZoneId = '';
   electronicSignature: ElectronicSignatureType;
   totalStorageSpace = 0;
   image = '';
   industries?: IndustryDTO[];
   isDemo: boolean;
   tenantId = '';
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.domicilio = new DomicilioDTO();
      this.pais = new PaisDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.domicilio != null) { this.domicilio.PrepareDTO(jsonObj.domicilio); }
      if (jsonObj.pais != null) { this.pais.PrepareDTO(jsonObj.pais); }
      if (jsonObj.name != null) { this.name = jsonObj.name; }
      if (jsonObj.telefono != null) { this.telefono = jsonObj.telefono; }
      if (jsonObj.artPoliza != null) { this.artPoliza = jsonObj.artPoliza; }
      if (jsonObj.artNombre != null) { this.artNombre = jsonObj.artNombre; }
      if (jsonObj.artPrivado != null) { this.artPrivado = jsonObj.artPrivado; }
      if (jsonObj.artPublico != null) { this.artPublico = jsonObj.artPublico; }
      if (jsonObj.razonSocial != null) { this.razonSocial = jsonObj.razonSocial; }
      if (jsonObj.identificadorFiscal != null) { this.identificadorFiscal = jsonObj.identificadorFiscal; }
      if (jsonObj.sucursal != null) { this.sucursal = jsonObj.sucursal; }
      if (jsonObj.uaalooImage != null) { this.uaalooImage = jsonObj.uaalooImage; }
      if (jsonObj.timeZoneId != null) { this.timeZoneId = jsonObj.timeZoneId; }
      if (jsonObj.electronicSignature != null) { this.electronicSignature = jsonObj.electronicSignature as ElectronicSignatureType; }
      if (jsonObj.totalStorageSpace != null) { this.totalStorageSpace = jsonObj.totalStorageSpace; }
      if (jsonObj.image != null) { this.image = jsonObj.image; }
      if (jsonObj.tenantId != null) { this.tenantId = jsonObj.tenantId; }
      if (jsonObj.industries != null) {
         this.industries = [];
         this.industries = jsonObj.industries;
      }
      if (jsonObj.isDemo != null) { this.isDemo = jsonObj.isDemo; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
