import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ActionsSnackBarData, ActionsSnackBarParameters } from 'src/app/shared/snack-bar/interfaces/actions-snack-bar-parameters.interface';
import { SnackBarParameters } from 'src/app/shared/snack-bar/interfaces/snack-bar-parameters.interface';
import { MultiActionSnackComponent } from 'src/app/shared/snack-bar/multi-action-snack/multi-action-snack.component';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  private readonly typePanelClass = {
    success: 'info-snackbar',
    info: 'info-snackbar',
    error: 'error-snackbar',
  };

  constructor(
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
  ) {}

  public showInfo(message: string, parameters?: SnackBarParameters): void {
    let finalMessage = message;

    if (!parameters || parameters.translate === undefined || parameters.translate) {
      finalMessage = this.translateService.instant(message, parameters?.interpolateParams || {});
    }

    const actionMessage =
      parameters?.action?.labelKey?.length > 0
        ? this.translateService.instant(parameters.action.labelKey)
        : this.translateService.instant('GENERAL.CLOSE');

    const snakRef = this.snackBar.open(finalMessage, actionMessage, {
      duration: parameters?.duration ?? 4000,
      panelClass: ['info-snackbar'],
    });

    if (parameters?.action !== undefined) {
      snakRef.onAction().subscribe(() => {
        parameters.action.command.execute();
      });
    }
  }

  public showActions(message: string, parameters: ActionsSnackBarParameters): void {
    let finalMessage = message;

    if (parameters.translate === undefined || parameters.translate) {
      finalMessage = this.translateService.instant(message, parameters?.interpolateParams || {});
    }

    const panelClass = ['no-max-width', this.typePanelClass[parameters?.type ?? 'info']];
    const data: ActionsSnackBarData = {
      text: finalMessage,
      actions: parameters.actions,
      dismissButton: parameters.dismissButton ?? false,
      dismissOnAction: parameters.dismissOnAction ?? false,
      isColumnLayout: parameters.isColumnLayout ?? false,
    };

    this.snackBar.openFromComponent(MultiActionSnackComponent, {
      data,
      duration: parameters?.duration ?? 5000,
      panelClass,
    });
  }

  public showWarning(message: string, parameters?: SnackBarParameters): void {
    let finalMessage = message;

    if (!parameters || parameters.translate === undefined || parameters.translate) {
      finalMessage = this.translateService.instant(message, parameters?.interpolateParams || {});
    }

    this.snackBar.open(finalMessage, this.translateService.instant('GENERAL.CLOSE'), {
      duration: parameters?.duration ?? 6000,
      panelClass: ['warning-snackbar'],
    });
  }

  public showError(message: string, parameters?: SnackBarParameters): void {
    let finalMessage = message;

    if (!parameters || parameters.translate === undefined || parameters.translate) {
      finalMessage = this.translateService.instant(message, parameters?.interpolateParams || {});
    }

    this.snackBar.open(finalMessage, this.translateService.instant('GENERAL.CLOSE'), {
      duration: parameters?.duration ?? 6000,
      panelClass: ['error-snackbar'],
    });
  }
}
