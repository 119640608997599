import { DOCUMENT } from '@angular/common';
import { Component, computed, EventEmitter, Inject, Input, Output, Signal, signal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReclutamientoOfertaPublicaDTO } from '@api/interfaces/reclutamiento-oferta-publica.interface';
import { TranslateService } from '@ngx-translate/core';
import { ReclutamientoEstadoOferta } from 'src/app/ModelDTO/DTO/reclutamientoEstadoOferta.ENUM';
import { RemunerationPeriod } from 'src/app/ModelDTO/DTO/remunerationPeriod.ENUM';
import { RemunerationType } from 'src/app/ModelDTO/DTO/remunerationType.ENUM';
import { WorkLocationType } from 'src/app/ModelDTO/DTO/workLocationType.ENUM';
import { OfferRequirements } from 'src/app/public/oferta/offer-requirements/offer-requirements.component';
import { confidencialImage } from 'src/app/shared/constants';

@Component({
  selector: 'app-email-validation-form',
  templateUrl: './email-validation-form.component.html',
  styleUrls: ['./email-validation-form.component.scss'],
})
export class EmailValidationFormComponent {
  @Input() public validateEmailForm: FormGroup;
  @Input() public hideForm: boolean;
  @Output() public validateEmail = new EventEmitter();
  @Input() public set offer(value: ReclutamientoOfertaPublicaDTO) {
    this.publicOffer = value;
    this.$offerRequirements.set({
      languages: value?.languages ?? [],
      transfers: value?.transfers ?? [],
      academicTrainings: value?.academicTrainings ?? [],
      complementaryTrainings: value?.complementaryTrainings ?? [],
      technicalSkills: value?.technicalSkills ?? [],
      softSkills: value?.softSkills ?? [],
    });
  }

  public publicOffer: ReclutamientoOfertaPublicaDTO;
  public readonly WorkLocationType = WorkLocationType;
  public readonly ReclutamientoEstadoOferta = ReclutamientoEstadoOferta;
  public readonly RemunerationType: RemunerationType;
  public readonly RemunerationPeriod: RemunerationPeriod;
  public $offerRequirements = signal<OfferRequirements>({
    languages: [],
    transfers: [],
    academicTrainings: [],
    complementaryTrainings: [],
    technicalSkills: [],
    softSkills: [],
  });
  public $showRequirements: Signal<boolean>;
  public get companyImage(): string {
    return this.publicOffer.companyImage ? this.publicOffer.companyImage : confidencialImage;
  }

  public get remunerationLabel(): string {
    const type =
      this.publicOffer.remunerationType !== RemunerationType.Undefined
        ? this.translate.instant(`ENUM.RECRUITMENT.TYPE.${RemunerationType[this.publicOffer.remunerationType]}`)
        : '';

    const period =
      this.publicOffer.remunerationPeriod !== RemunerationPeriod.Undefined
        ? this.translate.instant(`ENUM.RECRUITMENT.PERIOD.${RemunerationPeriod[this.publicOffer.remunerationPeriod]}`)
        : '';

    return this.translate.instant('RECRUITMENT.REMUNERATION', { type, period });
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translate: TranslateService,
  ) {
    this.$showRequirements = computed(
      () =>
        this.$offerRequirements()?.languages?.length > 0 ||
        this.$offerRequirements()?.transfers?.length > 0 ||
        this.$offerRequirements()?.academicTrainings?.length > 0 ||
        this.$offerRequirements()?.technicalSkills?.length > 0 ||
        this.$offerRequirements()?.softSkills?.length > 0,
    );
  }

  public onSubmit(): void {
    this.validateEmail.emit();
  }

  public scrollEmail(): void {
    this.document.getElementById('email').scrollIntoView({ behavior: 'smooth' });
  }
}
