import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, signal } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { AcademicTrainingLevel } from '@api/enums/academic-training-level.enum';
import { LanguageLevel } from '@api/enums/language-level.enum';
import { TechnicalSkillLevel } from '@api/enums/technical-skill-level.enum';
import { TransferType } from '@api/enums/transfer-type.enum';
import { RecruitmentOfferAcademicTrainingDTO } from '@api/interfaces/recruitment-offer-academic-training.interface';
import { RecruitmentOfferComplementaryTrainingDTO } from '@api/interfaces/recruitment-offer-complementary-training.interface';
import { RecruitmentOfferLanguageDTO } from '@api/interfaces/recruitment-offer-language.interface';
import { RecruitmentOfferSoftSkillDTO } from '@api/interfaces/recruitment-offer-soft-skill.interface';
import { RecruitmentOfferTechnicalSkillDTO } from '@api/interfaces/recruitment-offer-technical-skill.interface';
import { RecruitmentOfferTransferDTO } from '@api/interfaces/recruitment-offer-transfer.interface';
import { TranslateModule } from '@ngx-translate/core';
import { EnumTranslatePipe } from 'src/app/shared/lib/ngx-neo-pipes/enumTranslate.pipe';

export type OfferRequirements = {
  transfers?: RecruitmentOfferTransferDTO[];
  languages?: RecruitmentOfferLanguageDTO[];
  academicTrainings?: RecruitmentOfferAcademicTrainingDTO[];
  complementaryTrainings?: RecruitmentOfferComplementaryTrainingDTO[];
  technicalSkills?: RecruitmentOfferTechnicalSkillDTO[];
  softSkills?: RecruitmentOfferSoftSkillDTO[];
};

type Requirements = {
  [K in keyof OfferRequirements]: {
    mandatory: boolean;
    list: OfferRequirements[K];
  };
};

@Component({
  selector: 'app-offer-requirements',
  standalone: true,
  imports: [CommonModule, EnumTranslatePipe, MatIconModule, MatExpansionModule, TranslateModule],
  templateUrl: './offer-requirements.component.html',
  styleUrls: ['./offer-requirements.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferRequirementsComponent {
  @Input({ required: true }) public set requirements(value: OfferRequirements) {
    if (value) {
      this.$requirements.set({
        languages: { mandatory: value?.languages?.some((x) => x.exclusive), list: value?.languages ?? [] },
        transfers: { mandatory: value?.transfers?.some((x) => x.exclusive), list: value?.transfers ?? [] },
        academicTrainings: { mandatory: value?.academicTrainings?.some((x) => x.mandatory), list: value?.academicTrainings ?? [] },
        complementaryTrainings: {
          mandatory: value?.complementaryTrainings?.some((x) => x.mandatory),
          list: value?.complementaryTrainings ?? [],
        },
        technicalSkills: { mandatory: value?.technicalSkills?.some((x) => x.mandatory), list: value?.technicalSkills ?? [] },
        softSkills: { mandatory: false, list: value?.softSkills ?? [] },
      });
    }
  }

  public $requirements = signal<Requirements>({});
  public readonly TransferType = TransferType;
  public readonly LanguageLevel = LanguageLevel;
  public readonly AcademicTrainingLevel = AcademicTrainingLevel;
  public readonly TechnicalSkillLevel = TechnicalSkillLevel;
}
