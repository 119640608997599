import { Injectable, NgZone } from '@angular/core';
import { NamedBlobDTO as NamedBlobApiDTO } from '@api/interfaces/named-blob.dto';
import { BehaviorSubject, Observable, fromEvent } from 'rxjs';
import { NamedBlobDTO } from '../../models/DTO/namedBlob.DTO';
declare var device: any; // For typecript compilation
declare let resolveLocalFileSystemURL: any;

function _window(): any {
  // return the global native browser window object
  return window;
}

@Injectable({
  providedIn: 'root',
})
export class CordovaService {
  private pResume: BehaviorSubject<boolean>;

  public resume: Observable<boolean>;

  constructor(private zone: NgZone) {
    this.pResume = new BehaviorSubject<boolean>(null);
    this.resume = this.pResume.asObservable();

    fromEvent(document, 'resume').subscribe((event) => {
      this.zone.run(() => {
        this.onResume();
      });
    });

    if (this.isCordovaApp) {
      _window().open = this.cordova.InAppBrowser.open;
    }
  }

  get cordova(): any {
    return _window().cordova;
  }

  get isCordovaApp(): boolean {
    return _window().hasOwnProperty('cordova');
  }

  get isAngularApp(): boolean {
    return !this.isCordovaApp;
  }

  get platform(): string {
    if (this.isCordovaApp) {
      return device.platform;
    } else {
      return 'Angular';
    }
  }

  get deviceVersion(): string {
    if (this.isCordovaApp) {
      return device.version;
    } else {
      return _window().navigator.appVersion;
    }
  }

  get deviceUuid(): string {
    if (this.isCordovaApp) {
      return device.uuid;
    } else {
      return null;
    }
  }

  get isIOSApp() {
    return this.platform === 'iOS';
  }

  get isAndroidApp() {
    return this.platform === 'Android';
  }

  public onResume(): void {
    this.pResume.next(true);
  }

  public saveBlobToFile(blob: Blob, filename: string): Promise<string> {
    return new Promise((resolve, reject) => {
      (window as any).requestFileSystem(
        0,
        0,
        (fs) => {
          fs.root.getFile(
            filename,
            { create: true, exclusive: false },
            (fileEntry) => {
              fileEntry.createWriter((fileWriter) => {
                fileWriter.onwriteend = () => {
                  resolve(fileEntry.toURL());
                };
                fileWriter.onerror = (e) => reject(e);
                fileWriter.write(blob);
              });
            },
            (err) => reject(`Error getting file: ${err}`),
          );
        },
        (err) => reject(`Error getting filesystem: ${err}`),
      );
    });
  }

  public async downloadAndOpen(namedBlob: NamedBlobDTO | NamedBlobApiDTO) {
    try {
      const localUrl = await this.saveBlobToFile(namedBlob.blob, namedBlob.name);

      (window as any).cordova.plugins.fileOpener2.open(localUrl, namedBlob.mimeType, {
        error: (error: any) => {
          throw new Error(`Error opening file: ${error.message}`);
        },
        success: () => {
          // console.log('File opened successfully');
        },
      });
    } catch (error) {
      throw new Error(`'Error downloading and opening file: ${error}`);
    }
  }

  public setStatusBarBlack() {
    (window as any).StatusBar.styleDefault();
  }

  public setStatusBarWhite() {
    (window as any).StatusBar.styleLightContent();
  }

  public backgroundColorByHexString(colorHex: string) {
    (window as any).StatusBar.backgroundColorByHexString(colorHex);
  }

  public getLocalFile(relativePath: string): Promise<File> {
    return new Promise((resolve, reject) => {
      const path = (window as any).cordova.file.applicationDirectory + 'www/' + relativePath;

      resolveLocalFileSystemURL(
        path,
        (fileEntry: any) => {
          fileEntry.file(
            (file: File) => resolve(file),
            (error) => reject(error),
          );
        },
        (error) => reject(error),
      );
    });
  }

  public async getLocalFileAsBase64(relativePath: string): Promise<string> {
    try {
      const file = await this.getLocalFile(relativePath);
      const reader = new FileReader();

      return new Promise((resolve, reject) => {
        reader.onloadend = () => {
          const base64 = reader.result as string;
          resolve(base64);
        };

        reader.onerror = (e) => {
          reject(e);
        };

        reader.readAsDataURL(file);
      });
    } catch (error) {
      throw new Error(`Error getting base64 content: ${error}`);
    }
  }

  public openLink(link: string): void {
    window.open(link, this.isIOSApp ? '_system' : '_blank');
  }
}
