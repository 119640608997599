<button mat-icon-button [matMenuTriggerFor]="menu" [disabled]="!hasForm">
  <mat-icon fontSet="material-symbols-outlined">more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <button color="primary" mat-menu-item (click)="openPlanningModalAction()">
    <mat-icon fontSet="material-symbols-outlined" class="mr-2">event_note</mat-icon><span>{{ 'FORMS.SEE_PLANNING' | translate }}</span>
  </button>
  <button color="primary" mat-menu-item (click)="openTemplateModalAction()">
    <mat-icon fontSet="material-symbols-outlined" class="mr-2">assignment</mat-icon><span>{{ 'FORMS.SEE_TEMPLATE' | translate }}</span>
  </button>
  <button color="primary" mat-menu-item (click)="exportFormAction()">
    <mat-icon fontSet="material-symbols-outlined" class="mr-2">cloud_download</mat-icon><span>{{ 'GENERAL.EXPORT' | translate }}</span>
  </button>
</mat-menu>
