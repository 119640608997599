import { Component, OnInit } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { NgxNeoModalMatService } from 'src/app/shared/lib/ngx-neo-modal-mat/public_api';
import { TERMINAL } from 'src/app/shared/localStorageConstants';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';

@Component({
  templateUrl: './clock-in-out-terminal-dialog.component.html',
  styleUrls: ['./clock-in-out-terminal-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, MatFormFieldModule, MatDialogModule, MatInputModule, MatButtonModule, TranslateModule],
})
export class ClockInOutTerminalDialogComponent implements OnInit {
  public terminal: string;

  constructor(
    private dialog: MatDialogRef<ClockInOutTerminalDialogComponent>,
    private neoModalService: NgxNeoModalMatService,
  ) {}

  public ngOnInit(): void {
    this.terminal = localStorage.getItem(TERMINAL) ?? '';
  }

  public cancel(): void {
    this.dialog.close();
  }

  public accept(): void {
    if (this.terminal) {
      localStorage.setItem(TERMINAL, this.terminal);
      this.dialog.close(this.terminal);
    } else {
      this.neoModalService.warning('TIME_MANAGEMENT.TERMINAL_NAME_REQUEST');
    }
  }
}
