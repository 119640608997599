import { computed, Injectable, signal, Signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RelationshipPositionEnum } from '@api/enums/relationship-position-enum.enum';
import { PersonalDTO } from '@api/interfaces/personal.interface';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { DetalleEmpleadoModalComponent } from 'src/app/layout/administrador/colaboradores/nomina/detalle-empleado-modal/detalle-empleado-modal.component';
import { featureOnlyAccessOwnTeam, featureReducedTeammateProfileAccess } from 'src/app/shared/feature-flags/feature-flag-provider.service';
import { FeatureFlagHelper } from 'src/app/shared/feature-flags/feature-flag.helper';
import { FeatureFlagService } from 'src/app/shared/feature-flags/feature-flags.service';

@Injectable()
export class ProfileAccessService {
  public $canSeeCompleteProfile: Signal<boolean>;
  public $teammate = signal<PersonalDTO | null>(null);
  private $canOnlySeeTeam: Signal<boolean>;
  private $canShowFullTeammateProfile: Signal<boolean>;

  constructor(
    private featureFlagService: FeatureFlagService,
    private headerService: HeaderAppService,
    private matDialog: MatDialog,
  ) {
    const { $flags } = this.featureFlagService;

    this.$canOnlySeeTeam = computed(() => FeatureFlagHelper.featureOn(featureOnlyAccessOwnTeam, $flags().flags));
    this.$canShowFullTeammateProfile = computed(() => FeatureFlagHelper.featureOff(featureReducedTeammateProfileAccess, $flags().flags));
    this.$canSeeCompleteProfile = computed(() => {
      const isSubordinateOfSupervisor = this.$canOnlySeeTeam()
        ? this.$teammate()?.relationshipPosition === RelationshipPositionEnum.Superior
        : false;

      return (
        ((this.$canShowFullTeammateProfile() && !this.$canOnlySeeTeam()) || isSubordinateOfSupervisor) && this.headerService.modoEmpresa()
      );
    });
  }

  public openProfile(teammateId: number): void {
    this.matDialog.closeAll();
    this.matDialog.open(DetalleEmpleadoModalComponent, {
      panelClass: ['full-size-sm-1200-lg-modal', 'full-height-sm-700-lg-modal'],
      disableClose: false,
      data: { teammateId },
    });
  }
}
