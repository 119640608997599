<mat-card *ngIf="show && mensaje">
  <mat-card-content>
    <div class="d-flex flex-row align-items-center w-100">
      <img [src]="emptyStateImg" height="60" priority *ngIf="!default" />
      <p class="gray-text-muted" [ngClass]="default ? 'text-center w-100' : 'color-white pl-6'">
        {{ mensaje | translate }}
        <ng-content></ng-content>
      </p>
      <button (click)="show = false" *ngIf="showCloseButton" mat-icon-button>
        <mat-icon fontSet="material-symbols-outlined">close</mat-icon>
      </button>
    </div>
  </mat-card-content>
</mat-card>
